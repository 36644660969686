.body {
  margin: 0;
  font-size: 12px;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(-90deg,#ffffaa,#ffffff);
}
.fondo {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(-90deg,#ffffaa,#ffffff);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.centrado{
  text-align: center;
}
.numeroCeldaCh{
  width: 50px;
  text-align: right;
}
.numero{
  text-align: right;
}
.total{
  text-align: right;
  font-size:16px;
}
.rojo{
 color:red;
 font-weight: bold; 
}
.negro{
  color:black;
  font-weight: bold; 
 }
 .opcion{
  color:black;
}
.form-control-sm{
  height:calc(1.2em + .5rem + 2px);
}
.btn-sm{
  padding:.25rem .5rem;
  font-size:.875rem;
  line-height:1.2;
  border-radius:.2rem
}
.input-group-text{
  height:calc(1.2em + .5rem + 0px);
}
.opcionActual{
  text-align: center;
  font-size: medium;
  font-weight: bold;
  color:red;
}
ul {
  list-style:none;
  padding-left: 10px;
}
.opcionMenu{
  font-size:14px;
  color:black;
}
.opcionMenu::before {content: "• "; color: white; padding-left: 5px;}
li:hover {
  background-color:#f35b50;
  color:white;
}
.celdaNumero{
  float: 'right';
  vertical-align: middle;
  text-align: right;
}
.fondoBlanco>div>div{ background-color:white !important; }
.fondoGris>div>div{ background-color:whitesmoke !important; }